// import Link from "next/link";
import { Theme } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import AccessAlarm from "@mui/icons-material/AccessAlarm";
import makeStyles from "@mui/styles/makeStyles";

import DashboardLayout from "../src/components/Layout/DashboardLayout";
// import { MeConsumer } from "../context/MeContext";
import generateMetaTitle from "../lib/generateMetaTitle";
import MyLink from "../src/components/MyLink";

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: theme.spacing(2),
    "&:last-child": {
      padding: theme.spacing(2),
    },
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      "& > .MuiGrid-item": {
        width: "100%",
      },
    },
  },
  message: {
    fontSize: theme.typography.h4.fontSize,
    display: "inline-block",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h5.fontSize,
      display: "block",
      width: "100%",
      margin: "0 auto",
      textAlign: "center",
    },
  },
  icon: {
    color: theme.palette.text.primary,
    width: 24,
    height: 24,
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
      display: "block",
      margin: "0 auto",
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
    },
  },
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Card>
        <CardContent className={classes.cardContainer}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item>
              <AccessAlarm className={classes.icon} />
              <Typography align="left" className={classes.message}>
                Remember to clock your attendance!
              </Typography>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <MyLink
                href={"/attendances/clock-in"}
                as={"/attendances/clock-in"}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  Clock In
                </Button>
              </MyLink>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <MeConsumer>
        {({ loading, data }) => (
          <div>
            {loading ? "loading..." : data && data.me ? data.me._id : undefined}
          </div>
        )}
      </MeConsumer>
      <Link href="/login">
        <a>login</a>
      </Link>
      <br />
      <Link href="/logout">
        <a>logout</a>
      </Link> */}
    </div>
  );
};

IndexPage.Layout = DashboardLayout;
IndexPage.layoutProps = { title: "Dashboard" };
IndexPage.metaProps = { title: generateMetaTitle("Dashboard") };

// IndexPage.getInitialProps = async function ({ pathname }: any) {
//   return {
//     pathname: pathname,
//   };
// };

export default IndexPage;
